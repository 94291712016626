import * as React from "react"
import Layout from "../components/ui/layout"
import Seo from "../components/ui/seo"
import HeroBlock from "../components/home/HeroBlock"
import HomeLinks from "../components/home/HomeLinks"
import { refreshClosedStoreSettings } from "../contexts/actions"
import { StoreSettingsContext } from "../contexts"

const IndexPage = () => {
  const { storeSettings, dispatchStoreSettings } =
    React.useContext(StoreSettingsContext)

  React.useEffect(() => {
    dispatchStoreSettings(refreshClosedStoreSettings())
  }, [dispatchStoreSettings])

  return (
    <Layout footerOne={true} footerTwo={true}>
      <Seo title="Home" />
      <HeroBlock />
      <HomeLinks
        storeSettings={storeSettings}
        dispatchStoreSettings={dispatchStoreSettings}
      />
    </Layout>
  )
}

export default IndexPage
