import * as React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Alert from "@material-ui/lab/Alert"
import TakeoutDiningOutlinedIcon from "@mui/icons-material/TakeoutDiningOutlined"
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingBottom: "1rem",
    paddingTop: "5rem",
    paddingLeft: 0,
  },
  itemBox: {
    maxWidth: "20rem",
    textAlign: "left",
  },
  ordButton: {
    marginTop: "1rem",
  },

  itemIcon: { fontSize: "3rem !important" },

  inContainer: {
    textAlignLast: "left",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    textAlign: "left",
    marginBottom: "5rem",
  },
}))

const HomeLinks = ({ storeSettings }) => {
  const classes = useStyles()
  return (
    <Container maxWidth="md">
      <Box classes={{ root: classes.mainContainer }} id={"products-section"}>
        <Grid container justifyContent="space-evenly" alignItems="center">
          {!storeSettings.isTakeAwayClosed && (
            <Grid item xs={12} md={6} classes={{ root: classes.inContainer }}>
              <Box classes={{ root: classes.itemBox }}>
                <TakeoutDiningOutlinedIcon
                  classes={{ root: classes.itemIcon }}
                />
                <Typography variant={"h5"}>{"Takeaway"}</Typography>
                <Typography variant={"body1"}>
                  {"Place your takeaway order online."}
                </Typography>
                <Button
                  component={Link}
                  to={"/menu"}
                  variant="contained"
                  size="large"
                  color="primary"
                  disableElevation
                  classes={{ root: classes.ordButton }}
                >
                  <Typography variant={"h6"}>{"Order Now"}</Typography>
                </Button>
              </Box>
            </Grid>
          )}
          {!storeSettings.isDineInClosed && (
            <Grid item xs={12} md={6} classes={{ root: classes.inContainer }}>
              <Box classes={{ root: classes.itemBox }}>
                <QrCodeScannerOutlinedIcon
                  classes={{ root: classes.itemIcon }}
                />
                <Typography variant={"h5"}>{"Table Order"}</Typography>
                {storeSettings.isResOutOfHours ? (
                  <Alert severity="error">
                    <Typography variant={"body1"}>
                      {
                        "Unfortunately our kitchen is now closed, and the table ordering service is not available."
                      }
                    </Typography>
                  </Alert>
                ) : (
                  <Typography variant={"body1"}>
                    {
                      "Please use your mobile's camera to scan the QR code on your table to get started with your order."
                    }
                  </Typography>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  )
}

export default HomeLinks
